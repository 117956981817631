import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jricher/Projects/oauth.xyz-site/src/components/layout.js";
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Code from '../components/code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Tokens" keywords={['oauth', 'authorization', 'security']} mdxType="SEO" />
    <h1>{`Tokens`}</h1>
    <p>{`Tokens in GNAP are issued in response to a request for access from the client instance. Unlike OAuth 2, tokens are never issued in the front channel via the browser regardless of the mode or type of client.`}</p>
    <p>{`Access token formats and values are opaque to the client, but must be known to both the AS (so that it can issue them), and sometimes to the RS (so that it can interpret them, unless it uses an externalized service for doing so). In all of these examples, we'll be using a reference-style opaque token value.`}</p>
    <h2>{`Key-bound Access Tokens`}</h2>
    <p>{`By default, tokens in GNAP are issued bound to a key. `}<Link to="/keys" mdxType="Link">{`Proof of possession of the token's bound key`}</Link>{` must be presented alongside the token whenever that token is used. `}</p>
    <p>{`Unless otherwise specified by the AS, this key is the same key and proof mechanism the client instance proved possession of in its initial `}<Link to="/request" mdxType="Link">{`request`}</Link>{`.`}</p>
    <Code from="as" to="client" code={{
      "access_token": {
        "value": "OS9M2PMHKUR64TB8N6BW7OZB8CDFONP219RP1LT0",
        "access": ["one", "two", "and another thing"]
      }
    }} mdxType="Code" />
    <p>{`Bound access tokens are presented using the proofing mechanism associated with the key.`}</p>
    <Code from="client" to="as" language='http' codeString={`GET /resource HTTP/1.1
Host: resources.example.com
Signature-Input: gnap=("@request-target" "host" "authorization");created=1624564850;keyid="xyz-client"
Signature: gnap=:EN/rExQ/knVi61P5AFhyMGN7aVPzk/9C7nsYAWF2RvzsoV1uNxGZklM55qCIQpuhoNty4EhiH7iwuzZBbRCQcQ==:
Authorization: GNAP OS9M2PMHKUR64TB8N6BW7OZB8CDFONP219RP1LT0`} mdxType="Code" />
    <p>{`Bound tokens are used both at calls to the RS as well as all calls to the `}<Link to="/continue" mdxType="Link">{`continuation endpoint`}</Link>{` at the AS.`}</p>
    <h2>{`AS-supplied Token Keys`}</h2>
    <p>{`Alternatively, the AS can generate a key key generated by the AS and handed to the client as part of the token response, using the same structures the client instance uses to describe its keys and bindings.`}</p>
    <Code from="as" to="client" code={{
      "access_token": {
        "value": "OS9M2PMHKUR64TB8N6BW7OZB8CDFONP219RP1LT0",
        "access": ["one", "two", "and another thing"],
        "key": {
          proof: "httpsig",
          jwk: {
            "kty": "EC",
            "d": "wcHNx8kkBCcBnGY39K995TShcdOFdKtaRQLGrUELqBI",
            "crv": "P-256",
            "x": "m5dnqNXawIKF3qyCfs_raR1LtTKUtyf4t2uVa4Wmd6A",
            "y": "prF8Lo5JC2JTyj2GwtaI2LWWEaRa6v6XykjUMg-9C1U",
            "alg": "ES256"
          }
        }
      }
    }} mdxType="Code" />
    <p>{`The client instance then uses this specified key when presenting the token, instead of its own key. The AS could choose this mode of operation if it knows the downstream RS needs a particular kind of key presentation, or needs a derived key. However, it's more common (and therefore the default behavior) for the AS to bind to the client's own key.`}</p>
    <h2>{`Bearer Tokens`}</h2>
    <p>{`A token issued without an associated key is a bearer token, and any party with access to the token can present it to the RS. Bearer tokens are requested by setting the `}<inlineCode parentName="p">{`bearer`}</inlineCode>{` flag in the `}<Link to="/request" mdxType="Link">{`request`}</Link>{`.`}</p>
    <Code from="client" to="as" code={{
      "access_token": {
        "flags": ["bearer"],
        "access": ["dolphin-metadata", "and another thing"]
      }
    }} mdxType="Code" />
    <p>{`If issued, a bearer token has the `}<inlineCode parentName="p">{`bearer`}</inlineCode>{` flag of the access token section set in the `}<Link to="/response" mdxType="Link">{`response`}</Link>{`. `}</p>
    <Code from="as" to="client" code={{
      "access_token": {
        "value": "OS9M2PMHKUR64TB8N6BW7OZB8CDFONP219RP1LT0",
        "access": ["dolphin-metadata", "and another thing"],
        "flags": ["bearer"]
      }
    }} mdxType="Code" />
    <p>{`The client instance sends this token to the RS using `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header method defined in RFC6750.`}</p>
    <Code from="client" to="rs" language='http' codeString={`GET / HTTP/1.1
Host: server.example.com
Authorization: Bearer OS9M2PMHKUR64TB8N6BW7OZB8CDFONP219RP1LT0`} mdxType="Code" />
    <h2>{`Multiple access tokens`}</h2>
    <p>{`A client can make a `}<Link to="/request" mdxType="Link">{`request`}</Link>{` with a multi-part `}<inlineCode parentName="p">{`access_token`}</inlineCode>{` section, which is formatted as a JSON array. Each object in the request requires a `}<inlineCode parentName="p">{`label`}</inlineCode>{` field with names chosen by the client to differentiate the access being requested. A request would look like this.`}</p>
    <Code from="client" to="as" code={{
      "access_token": [{
        "label": "token1",
        "access": [{
          "type": "example.com/resource-set",
          "actions": ["read", "write", "dolphin"],
          "locations": ["https://server.example.net/", "https://resource.local/other"],
          "datatypes": ["metadata", "images"]
        }]
      }, {
        "label": "token2",
        "access": [{
          "type": "example.com/another-api",
          "actions": ["foo", "bar", "dolphin"],
          "locations": ["https://resource.other/"],
          "datatypes": ["data", "pictures"]
        }]
      }]
    }} mdxType="Code" />
    <p>{`The returned access token structure is similar, with an array of uniquely-labeled access token objects.`}</p>
    <Code from="as" to="client" code={{
      "access_token": [{
        "label": "token1",
        "value": "OS9M2PMHKUR64TB8N6BW7OZB8CDFONP219RP1LT0",
        "access": [{
          "type": "example.com/resource-set",
          "actions": ["read", "write", "dolphin"],
          "locations": ["https://server.example.net/", "https://resource.local/other"],
          "datatypes": ["metadata", "images"]
        }]
      }, {
        "label": "token2",
        "value": "UFGLO2FDAFG7VGZZPJ3IZEMN21EVU71FHCARP4J1",
        "access": [{
          "type": "example.com/another-api",
          "actions": ["foo", "bar", "dolphin"],
          "locations": ["https://resource.other/"],
          "datatypes": ["data", "pictures"]
        }]
      }]
    }} mdxType="Code" />
    <p>{`The `}<inlineCode parentName="p">{`token1`}</inlineCode>{` access token in the response corresponds directly to the `}<inlineCode parentName="p">{`token1`}</inlineCode>{` portion of the request, and the same applies to `}<inlineCode parentName="p">{`token2`}</inlineCode>{`. The AS can't add an additional access token that the client did not specifically request (unless the advanced token splitting functionality is requested), but it can omit a requested access token in case it was not issued. This could be because the user denied the authorization request or some other policy decision required it. Each access token could have different flags, different management, and of course different access rights. `}</p>
    <h1>{`Managing Access Tokens`}</h1>
    <p>{`If the access token is issued alongside a token management URI:`}</p>
    <Code from="as" to="client" code={{
      "access_token": {
        "value": "OS9M2PMHKUR64TB8N6BW7OZB8CDFONP219RP1LT0",
        "access": ["one", "two", "and another thing"],
        "manage": "https://server.example.com/token/PRY5NM33OM4TB8N6BW7OZB8CDFONP219RP1L"
      }
    }} mdxType="Code" />
    <p>{`Then the client can send a POST request to the token management URL to get a new access token.`}</p>
    <p>{`The token itself is used to access the API at the management URL. If the token is bound to a specific key, the client has to present that key with the token as in any other request using the token. However, if the token is a `}<inlineCode parentName="p">{`bearer`}</inlineCode>{` token, the client has to prove possession of the same key that was used to issue the token. That is to say, bearer tokens are never used as the sole credentials for their own management.`}</p>
    <h2>{`Rotating Access Tokens`}</h2>
    <p>{`To rotate a token, the client sends an empty HTTP `}<inlineCode parentName="p">{`POST`}</inlineCode>{` to the token management URL.`}</p>
    <Code from="client" to="as" language='http' codeString={`POST /token/PRY5NM33OM4TB8N6BW7OZB8CDFONP219RP1L HTTP/1.1
Host: server.example.com
Signature-Input: gnap=("@request-target" "host" "authorization");created=1624564850;keyid="xyz-client"
Signature: gnap=:g0icu7VRfKaAmnArofz1m/S7ZdnybSO6gMncvwrUvWIXfB/DBltK0arEo5PqAv5vUKXSEb/7zkqZM4eeMf3xDg==:
Authorization: GNAP OS9M2PMHKUR64TB8N6BW7OZB8CDFONP219RP1LT0`} mdxType="Code" />
    <p>{`If the management URI is still valid, the AS revokes the old access token (if possible) and issues the new access token using the same format as in the `}<Link to="/response" mdxType="Link">{`response`}</Link>{`.`}</p>
    <Code from="as" to="client" code={{
      "access_token": {
        "value": "FKPLDO5394XVIWHVNR7POUNC4OYJ2LWKYYZGKFS6",
        "access": ["one", "two", "and another thing"],
        "manage": "https://server.example.com/token/PRY5NM33OM4TB8N6BW7OZB8CDFONP219RP1L"
      }
    }} mdxType="Code" />
    <p>{`Note that since this process could result in the management URL rotating upon each use. If no management URL is returned, the client can no longer rotate the token.`}</p>
    <p>{`If the client instance wants to get a new access token with a different set of rights, it can't use the token rotation mechanism and instead needs to send a `}<Link to="/continue" mdxType="Link">{`continuation request`}</Link>{` with the new parameters.`}</p>
    <h2>{`Revoking Access Tokens`}</h2>
    <p>{`A client can request the revocation of a token by sending a `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{` request to the token management URL.`}</p>
    <Code from="client" to="rs" language='http' codeString={`DELETE /token/PRY5NM33OM4TB8N6BW7OZB8CDFONP219RP1L HTTP/1.1
Host: server.example.com
Signature-Input: gnap=("@request-target" "host" "authorization");created=1624564850;keyid="xyz-client"
Signature: gnap=:mcHk93cUDq1h/fb073O6oQM0Ek8tT+i2yZZHQOy05yLu0iiRUmVHC0K8m/kaB1Q/SXLYZeoadClcq7TVTvmZOA==:
Authorization: Bearer OS9M2PMHKUR64TB8N6BW7OZB8CDFONP219RP1LT0`} mdxType="Code" />
    <p>{`Both the AS and client throw out the access token after completion of the revocation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      